import React from "react";
import { useSubheader } from "_metronic/layout/index";

import { useMetaTags } from "app/utils/SeoFunction";
import ReactGA from "react-ga";
import { Card, CardColumns } from "react-bootstrap";

export const MyAccountHome = ({
  submissionStats,
  achievementStats,
}): React$MixedElement => {
  const suhbeader = useSubheader();
  useMetaTags("MyAccount Home");

  // console.log("submission stats", submissionStats);
  return (
    <>
      <div className="row">
        <div className="col-lg-6 d-none">
          <Card>
            {/* <Card.Img variant="top" src="holder.js/100px160" /> */}
            <Card.Body style={{ padding: 5 }}>
              <Card.Title
                className="text-dark"
                style={{ padding: 20, margin: 0 }}
              >
                <i className="flaticon-paper-plane-1 text-danger icon-lg"></i>
                Submissions
              </Card.Title>
            </Card.Body>
            <Card.Footer style={{ padding: 10 }}>
              {submissionStats && (
                <>
                  <div>Correct : {submissionStats.total_correct} </div>
                  <div>Wrong : {submissionStats.total_wrong} </div>
                  <div>% Correct : {submissionStats.percentage_correct} </div>
                  <div>Pending : {submissionStats.total_pending} </div>
                </>
              )}
            </Card.Footer>
          </Card>
        </div>
        <div className="col-lg-6">
          <Card>
            {/* <Card.Img variant="top" src="holder.js/100px160" /> */}
            <Card.Body style={{ padding: 5 }}>
              <Card.Title
                className="text-dark"
                style={{ padding: 20, margin: 0 }}
              >
                <i className="flaticon-paper-plane-1 text-danger icon-lg"></i>
                Achievements
              </Card.Title>
            </Card.Body>
            <Card.Footer style={{ padding: 10 }}>
              {achievementStats && (
                <>
                  <div>
                    Rank :{" "}
                    {achievementStats.rank != 999999999
                      ? achievementStats.rank
                      : "None"}
                  </div>
                  <div>Points : {achievementStats.points} </div>
                  <div>Score : {achievementStats.score} </div>
                  <div>Band : {achievementStats.band} </div>
                  <div>Badges : {achievementStats.badges} </div>
                </>
              )}
            </Card.Footer>
          </Card>
        </div>
      </div>
    </>
  );
};
