import { toAbsoluteUrl } from "_metronic/_helpers/AssetsHelpers";

// defines various layout types
export const LayoutEnum = Object.freeze({
  NoSideBar: "NoSideBar",
  RightSideBar2x: "RightSideBar2x",
  RightSideBar3x: "RightSideBar3x",
  RightSideBar4x: "RightSideBar4x",
  RightSideBar5x: "RightSideBar5x",
  RightSideBar6x: "RightSideBar6x",
  RightSideBar7x: "RightSideBar7x",
  RightSideBar8x: "RightSideBar8x",
  LeftSideBar2x: "LeftSideBar2x",
  LeftSideBar3x: "LeftSideBar3x",
  LeftSideBar4x: "LeftSideBar4x",
  LeftSideBar5x: "LeftSideBar5x",
  LeftSideBar6x: "LeftSideBar6x",
  LeftSideBar7x: "LeftSideBar7x",
  LeftSideBar8x: "LeftSideBar8x",
});

export type LayoutType = $Keys<typeof LayoutEnum>;

export const LayoutIdToLayoutTypeMap = {
  0: LayoutEnum.NoSideBar,
  1: LayoutEnum.RightSideBar1x, // 1
  2: LayoutEnum.RightSideBar2x, // 2
  3: LayoutEnum.RightSideBar3x, // 3
  4: LayoutEnum.RightSideBar4x, // 4
  5: LayoutEnum.RightSideBar5x, // 5
  6: LayoutEnum.RightSideBar6x, // 6
  7: LayoutEnum.RightSideBar7x, // 7
  8: LayoutEnum.RightSideBar8x, // 8
  11: LayoutEnum.LeftSideBar1x, // 2
  12: LayoutEnum.LeftSideBar2x, // 2
  13: LayoutEnum.LeftSideBar3x, // 3
  14: LayoutEnum.LeftSideBar4x, // 4
  15: LayoutEnum.LeftSideBar5x, // 5
  16: LayoutEnum.LeftSideBar6x, // 6
  17: LayoutEnum.LeftSideBar7x, // 7
  18: LayoutEnum.LeftSideBar8x, // 8
};

export const NodeTypeEnum = Object.freeze({
  1: {
    name: "Problem",
    guid: "problem",
    iconImage: toAbsoluteUrl("/media/svg/icons/General/Shield-protected.svg"),
  },
  2: {
    name: "Remedy",
    guid: "remedy",
    iconImage: toAbsoluteUrl("/media/svg/icons/General/Shield-check.svg"),
  },
  3: {
    name: "Post",
    guid: "post",
    iconImage: toAbsoluteUrl("/media/svg/files/doc.svg"),
  },
  4: {
    name: "Question",
    guid: "question",
    iconImage: toAbsoluteUrl("/media/svg/files/doc.svg"),
  },
  5: {
    name: "Answer",
    guid: "answer",
    iconImage: toAbsoluteUrl("/media/svg/files/doc.svg"),
  },
  6: {
    name: "Product",
    guid: "product",
    iconImage: toAbsoluteUrl("/media/svg/files/doc.svg"),
  },
  7: {
    name: "Item",
    guid: "item",
    iconImage: toAbsoluteUrl("/media/svg/files/doc.svg"),
  },
  8: {
    name: "Tool",
    guid: "tool",
    iconImage: toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg"),
  },
  9: {
    name: "Calculator",
    guid: "tool",
    iconImage: toAbsoluteUrl("/media/svg/icons/Tools/Compass.svg"),
  },
  10: {
    name: "Tracker",
    guid: "tool",
    iconImage: toAbsoluteUrl("/media/svg/icons/Navigation/Route.svg"),
  },
});
