/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import ProtectedRoute from "app/modules/Auth/ProtectedRoute";
import { AboutUsPage } from "app/pages/AboutUsPage";
import { BuilderPage } from "app/pages/BuilderPage";
import { CalculatoryHome } from "app/pages/CalculatorHome";
import { DashboardPage } from "app/pages/DashboardPage";
import { HomePage } from "app/pages/HomePage";
import { PrivacyPolicyPage } from "app/pages/PrivacyPolicyPage";
import { ProblemHome } from "app/pages/ProblemHome";
import { ProblemPage } from "app/pages/ProblemPage";
import { RemedyHome } from "app/pages/RemedyHome";
import { TermsOfServicePage } from "app/pages/TermsOfServicePage";
import { ToolHome } from "app/pages/ToolHome";
import { TrackerHome } from "app/pages/TrackerHome";
import ToolTemplate from "app/tools/ToolTemplate";
import { default as React, lazy } from "react";
import { Suspense } from "react-is";
import { Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "_metronic/layout/index";
import { AuthPage, Logout } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { RemedyPage } from "app/pages/RemedyPage";
import { SearchHome } from "app/pages/SearchHome";
import { LearnHome } from "app/pages/LearnHome";
import { AskHome } from "app/pages/AskHome";
import { QuestionListPage } from "app/pages/QuestionListPage";
import { MyQuestions } from "app/my/MyQuestions";
import { MyAccount } from "app/my/MyAccount";
import AccountSettings from "app/modules/UserProfile/AccountSettings";
import ChangePassword from "app/modules/UserProfile/ChangePassword";
import EmailSettings from "app/modules/UserProfile/NotificationsSettings";
import PersonaInformation from "app/modules/UserProfile/PersonaInformation";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const UserProfilePageLazy = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

/**
 * The component defines all the routs of the application
 * @returns
 */
export function Routes() {
  return (
    <Switch>
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/terms" component={TermsOfServicePage} />
          <Route exact path="/privacy" component={PrivacyPolicyPage} />
          <Route exact path="/about" component={AboutUsPage} />
          <Route exact path="/remedy/home" component={RemedyHome} />
          <Route exact path="/learn/home" component={LearnHome} />
          <Route exact path="/ask/home" component={AskHome} />
          <Route exact path="/problem/home" component={ProblemHome} />
          <Route exact path="/tool/home" component={ToolHome} />
          <Route path="/google-material" component={GoogleMaterialPage} />
          <Route path="/react-bootstrap" component={ReactBootstrapPage} />
          <Route path="/e-commerce" component={ECommercePage} />
          <Route path="/builder" component={BuilderPage} />
          <Route path="/auth" component={AuthPage} />
          <Route
            path="/tool/:url"
            exact
            render={(props) => (
              <ToolTemplate key={props.match.params.url} {...props} />
            )}
          />
          <Route path="/search/home" component={SearchHome} />
          <Route
            path="/problem/:guid/:seo_guid"
            exact
            render={(props) => (
              <ProblemPage key={props.match.params.guid} {...props} />
            )}
          />
          />
          <Route
            path="/remedy/:guid/:seo_guid"
            exact
            render={(props) => (
              <RemedyPage key={props.match.params.guid} {...props} />
            )}
          ></Route>
          <ProtectedRoute path="/my/account/:tab" component={MyAccount} />
          <ProtectedRoute path="/dashboard" component={DashboardPage} />
          <ProtectedRoute
            exact
            path="/user-profile"
            component={UserProfilePageLazy}
          />
          <Route exact path="/logout" component={Logout} />
          <Route path="*" component={ErrorsPage} />
        </Switch>
      </Suspense>
    </Switch>
  );
}
