// @flow
import ToolGrid from "app/components/ToolGrid";
import React from "react";
import { useEffect } from "react";
import ReactGA from "react-ga";
import { useSubheader } from "_metronic/layout/index";
import { Notice } from "_metronic/_partials/controls/Notice";
import { ToolType } from "app/utils/Constants";
import { useMetaTags } from "app/utils/SeoFunction";
import { recordPageView } from "app/utils/APIFunctions";

export const ToolHome = (): React$MixedElement => {
  const suhbeader = useSubheader();
  useMetaTags(
    "Gardening Tools",
    "Value add tools that help you become aware, understand and take control of your garden."
  );
  useEffect(() => {
    recordPageView("/tool/home");
    suhbeader.setTitle("Tools & Calculators");
  }, [suhbeader]);

  return (
    <>
      <Notice svg="/media/svg/icons/Tools/Compass.svg">
        <h1>Tools & Calculators</h1>
        Value add tools and calculators that help you become aware, understand
        and take control of your garden.
      </Notice>

      <ToolGrid toolType={ToolType.Tool}></ToolGrid>
    </>
  );
};
