// @flow
import { useSubheader } from "_metronic/layout/index";

import React from "react";
import { useEffect } from "react";
import { Notice } from "_metronic/_partials/controls/Notice";
import ReactGA from "react-ga";
import { useMetaTags } from "app/utils/SeoFunction";
import { recordPageView } from "app/utils/APIFunctions";

export const TermsOfServicePage = (): React$MixedElement => {
  const websiteName = process.env.REACT_APP_WEBSITE_NAME;
  const suhbeader = useSubheader();
  useMetaTags("Terms of Service");

  useEffect(() => {
    recordPageView("/terms");
    suhbeader.setTitle("Terms");
  }, [suhbeader]);

  return (
    <>
      <Notice svg="/media/svg/icons/Tools/Compass.svg">
        <div>
          <h2>Overview</h2>
          <div>
            If you continue to browse and use this website, mobile version of
            this website, and use any form of digital content (apps, books etc.)
            provided by {websiteName} (collectively referred a "Services"), your
            use constitute your acceptance of these Terms of Services. You are
            agreeing to comply with and be bound by the following terms and
            conditions of use, which together with our privacy policy govern{" "}
            {websiteName}
            's relationship with you in relation to these Services. If you
            disagree with any part of these terms and conditions, please do not
            use our Service. The term ‘{websiteName}’ or ‘us’ or ‘we’ refers to
            the owner of the Service. The term ‘you’ refers to the user or
            viewer of our Service. <br />
            <br />
          </div>
          <h2>Terms</h2>
          The use of this Service is subject to the following terms of use:
          <br />
          <br />
          <div>
            By using the Service, you agree and truthfully confirm that you are
            13 years or older in age. You acknowledge and confirm that your
            registration and profile information submitted by you is true and
            accurate and you will maintain the accuracy of such information. You
            are responsible for maintaining the security of your account.
            <br />
            <br />
          </div>
          <div>
            The content you post, the comments you write,files uploads or any
            material (collective referred as "Content") written or uploaded to
            or using any our Services must be related to lifestyle or health
            topics {websiteName}
            &nbsp; reserves the right to un-publish, remove,demote or delete an
            such Content that violates our Content Policy and terminate accounts
            of such offenders at it discretion without any notice , nor
            providing any explanation thereof. Any Content that is harmful,
            sexual in nature, obscene, promotes racism, is threatening, is
            hateful, violates privacy rights, copyrights, trademark rights,
            solicitation, advertising, un-authorized copy,content scraping,
            reselling of content,using viruses or bots, or denial of service
            attacks, spam or junk, impersonation or logging as others,
            commercial activities and/or sales without permission of{" "}
            {websiteName} constitutes violates our terms and conditions and
            appropriate legal action would be taken.
            <br />
            <br />
          </div>
          <div>
            By posting and uploading any content you agree and warrant that its
            original work or you have full rights for it, it does not violate
            infringe on any proprietary rights, including but not limited to
            copyright, trade secrets or intellectual property of your employer
            or any other third party.
            <br />
            <br />
          </div>
          <div>
            By submitting Content to {websiteName} on or through the Services,
            you grant us a worldwide, non-exclusive, royalty-free license (with
            the right to sublicense) to use, copy, reproduce, process, adapt,
            modify, publish, transmit, display and distribute such Content in
            any and all media or distribution methods (now known or later
            developed). Such additional uses by {websiteName}, or other
            companies or individuals who partner with {websiteName}, may be made
            with no compensation paid to you with respect to the Content that
            you submit, post, or otherwise make available through the Service.
            <br />
            <br />
          </div>
          <div>
            {websiteName} respects the intellectual property rights of others.
            If you believe that any Content located on or linked to by{" "}
            {websiteName} violates your copyright, please notify {websiteName}{" "}
            with proof of ownership.
            {websiteName} will act by including by removing the infringing
            Content or disabling all links to the infringing Content, as
            required or appropriate. A user who may infringe, once or
            repeatedly, the copyrights or other intellectual property rights of{" "}
            {websiteName} or others, {websiteName} may, in its discretion,
            terminate or deny access to, or use of, the Service.
            <br />
            <br />
          </div>
          <div>
            Neither we nor any third parties provide any warranty or guarantee
            as to the accuracy, timeliness, performance, completeness or
            suitability of the information and materials found or offered by our
            Services for any particular purpose. You acknowledge that such
            information and materials may contain inaccuracies or errors and we
            expressly exclude liability for any such inaccuracies or errors to
            the fullest extent permitted by law.
            <br />
            <br />
          </div>
          <div>
            Your use of any information or materials offered by our Services is
            entirely at your own risk, for which we shall not be liable. It
            shall be your own responsibility to ensure that any products,
            services or information available through our Services meet your
            specific requirements. Your access to and use of the Services or any
            Content are at your own risk.
            <br />
            <br />
          </div>
          <div>
            {websiteName} is not a replacement for professional medical opinion,
            examination, diagnosis or treatment. Always seek the advice of your
            medical doctor or other qualified health professional before
            starting any new treatment or making any changes to existing
            treatment. Do not delay seeking or disregard medical advice based on
            information written by any author on this site. No health questions
            and information on {websiteName} is regulated or evaluated by the
            Food and Drug Administration and therefore the information should
            not be used to diagnose, treat, cure or prevent any disease without
            the supervision of a medical doctor. The information on this site is
            not intended or implied to be a substitute for professional medical
            advice, diagnosis or treatment. All content, including text,
            graphics, images and information, contained on or available through
            this web site is for general information purposes only.
            <br />
            <br />
          </div>
          <div>
            Limitation of Liability : YOU ACKNOWLEDGE AND AGREE THAT, TO THE
            MAXIMUM EXTENT PERMITTED BY LAW, THE ENTIRE RISK ARISING OUT OF YOUR
            ACCESS TO AND USE OF THE {websiteName} SERVICES, AND THIRD PARTY
            MATERIALS THEREIN REMAINS WITH YOU. NEITHER
            {websiteName} NOR ANY OTHER PARTY INVOLVED IN CREATING, PRODUCING,
            OR DELIVERING THE {websiteName}
            SERVICES WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY OR
            CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOSS OF DATA OR LOSS
            OF GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE
            OR THE COST OF SUBSTITUTE PRODUCTS OR SERVICES, ARISING OUT OF OR IN
            CONNECTION WITH THESE TERMS OF USE OR FROM THE USE OF OR INABILITY
            TO USE THE {websiteName} SITE, {websiteName} SERVICES OR CONTENT
            THEREIN, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING
            NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND
            WHETHER OR NOT
            {websiteName} HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE,
            EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF
            ITS ESSENTIAL PURPOSE.
            <br />
            <br />
            IN NO EVENT WILL {websiteName}’s AGGREGATE LIABILITY ARISING OUT OF
            OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO
            USE THE SITE, SERVICES OR CONTENT THEREIN EXCEED ONE U.S. DOLLAR
            ($1). THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL
            ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN&nbsp;
            {websiteName} AND YOU. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION
            OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES,
            SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
            <br />
            <br />
          </div>
          <div>
            Disclaimers :{websiteName}’s SERVICES, THIRD PARTY MATERIALS AND
            YOUR ACCOUNT ARE PROVIDED “AS IS”, WITHOUT WARRANTY OF ANY KIND,
            EITHER EXPRESS OR IMPLIED. WITHOUT LIMITING THE FOREGOING,{" "}
            {websiteName} EXPLICITLY DISCLAIMS ANY WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT
            OR NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF
            DEALING OR USAGE OF TRADE. {websiteName} MAKES NO WARRANTY THAT THE
            {websiteName} SERVICES, THIRD PARTY MATERIALS OR YOUR ACCOUNT WILL
            MEET YOUR REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE,
            OR ERROR-FREE BASIS. <br />
            NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM{" "}
            {websiteName} OR THROUGH {websiteName} SERVICES OR YOUR CONTENT,
            WILL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN.
            <br />
            <br />
          </div>
          <div>
            You agree to indemnify us against any claims or legal proceedings
            that may arise through your use of the Services in breach of these
            Terms of Use by You (including any representation or warranty made
            by You hereunder). We will notify you of any such claims or
            proceedings and keep you informed as to the progress of such claims
            or proceedings.
            <br />
            <br />
          </div>
          <div>
            Our Services contains material which is owned by or licensed to us.
            This material includes, but is not limited to, the design, layout,
            look, appearance and graphics. Reproduction is prohibited other than
            in accordance with the copyright notice, which forms part of these
            terms and conditions.
            <br />
            <br />
          </div>
          <div>
            All trademarks reproduced in this website, which are not the
            property of, or licensed to the operator, are acknowledged on the
            website.
            <br />
            <br />
          </div>
          <div>
            Unauthorised use of this Services may give rise to a claim for
            damages and/or be a criminal offence.
            <br />
            <br />
          </div>
          <div>
            From time to time, our Services may also include links to other
            websites. These links are provided for your convenience to provide
            further information. They do not signify that we endorse the
            website(s). We have no responsibility for the content of the linked
            website(s).
            <br />
            <br />
          </div>
          <div>
            Confidentiality, your confidential use of this site cannot be
            guaranteed by us. We shall not be responsible for any harm that you
            or any person may suffer as a result of a breach of confidentiality
            in respect to your use of this site.
            <br />
            <br />
          </div>
          <div>
            Use outside the U.S, We do not represent that all content, materials
            and services on our Web site are appropriate or available for use in
            all geographic locations, especially some locations outside the
            United States, and accessing such from certain locations may be
            illegal and prohibited. Those who do access content, materials and
            services from such locations act on their own initiative and we are
            not responsible for their compliance with local laws or other
            applicable laws. You will not access the foregoing where prohibited
            by law.
            <br />
            <br />
          </div>
          <div>
            You may not use, export, re-export, import or transfer the Content
            and Services expect as authorized by United States law, the laws of
            the jurisdiction in which you are using {websiteName} Services, and
            any other applicable Laws. You also may not use the {websiteName}{" "}
            Services for any purpose prohibited by US law.
            <br />
            <br />
          </div>
          <div>The terms of service are subject to change without notice.</div>
          <div>Updated Jan 01, 2022</div>
        </div>
      </Notice>
    </>
  );
};
