import React, { useEffect, useState, useMemo } from "react";
import clsx from "clsx";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../../_core/MetronicLayout";
import { SearchDropdownResult } from "_metronic/layout/components/extras/dropdowns/search/SearchDropdownResult";
import { toAbsoluteUrl } from "../../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../../_partials/dropdowns";
import { runApiQuery } from "app/utils/APIFunctions";
import { gql } from "graphql-request";
import { NodeTypeEnum } from "app/utils/LibConfig";
import { ToolType } from "app/utils/Constants";
import ReactGA from "react-ga";

const SEARCH_NODES = gql`
  query($kw: String!) {
    nodeQuery(keywords: $kw) {
      __typename
      guid
      title
      tags
      descr
      node_type_id
      seo_guid
    }
  }
`;

export function SearchDropdown() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    console.log(
      "handleSearchChange ",
      event.target.value.length,
      event.target.value
    );
  };

  // load the options 0- make the api calls
  const loadResults = async (searchText) => {
    console.log("in : loadResults");
    const variables = {
      kw: searchText,
    };
    const data = await runApiQuery(SEARCH_NODES, variables);
    // map it into format as requried by the control
    console.log(data);

    // transform and set the data
    const searchData = data.nodeQuery?.map((node) => {
      let url;
      let nodeType = parseInt(node.node_type_id);
      if (
        nodeType === ToolType.Calculator ||
        nodeType === ToolType.Tool ||
        nodeType === ToolType.Tracker
      ) {
        url = `/${NodeTypeEnum[node.node_type_id].guid}/${
          node.seo_guid
        }?guid_type=node`;
      } else {
        url = `/${NodeTypeEnum[node.node_type_id].guid}/${node.guid}/${
          node.seo_guid
        }?guid_type=node`;
      }
      return {
        type: NodeTypeEnum[node.node_type_id].name,
        text: node.title,
        description: node.descr,
        iconClassName: "flaticon-psd font-brand",
        iconImage: NodeTypeEnum[node.node_type_id].iconImage,
        url: url,
      };
    });

    if (searchText.length > 4) {
      ReactGA.event({
        category: "Global Search",
        action: "Searched Keywords",
        label: searchText,
      });
    }

    console.log("search data", searchData);
    setData(searchData);
  };

  const clear = () => {
    console.log("In clear");
    setData(null);
    setSearchValue("");
  };

  useEffect(() => {
    const loaderFunc = async () => {
      console.log("In useEffect");
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (searchValue && searchValue.length > 2) {
        setLoading(true);

        await loadResults(searchValue);
        setLoading(false);
      }
    };
    loaderFunc();
  }, [searchValue]);

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.search.layout") ===
        "offcanvas",
    };
  }, [uiService]);

  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <div
            className="btn btn-icon btn-clean btn-lg mr-1"
            id="kt_quick_search_toggle"
          >
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")} />
            </span>
          </div>
        </div>
      )}
      {!layoutProps.offcanvas && (
        <Dropdown
          alignRight
          drop="down"
          onToggle={() => {
            setData(null);
            setLoading(false);
            setSearchValue("");
          }}
          id="kt_quick_search_toggle"
        >
          <Dropdown.Toggle as={DropdownTopbarItemToggler}>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="search-panel-tooltip">Quick search</Tooltip>
              }
            >
              <>
                <div className="btn btn-icon btn-clean btn-lg btn-dropdown mr-1">
                  <span className="svg-icon svg-icon-xl svg-icon-primary">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                    />
                  </span>
                </div>
              </>
            </OverlayTrigger>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
            <div
              id="kt_quick_search_dropdown"
              className={clsx("quick-search quick-search-dropdown", {
                "quick-search-has-result": data && data.length,
              })}
            >
              <form className="quick-search-form">
                <div className="input-group">
                  <div className={`input-group-prepend`}>
                    <span className="input-group-text">
                      <span className="svg-icon svg-icon-lg">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/Search.svg"
                          )}
                        />
                      </span>
                    </span>
                  </div>
                  <input
                    type="text"
                    autoFocus={true}
                    placeholder="Search..."
                    value={searchValue}
                    onChange={handleSearchChange}
                    className="form-control"
                  />

                  <div
                    className={`input-group-append ${
                      loading ? "spinner spinner-sm spinner-primary" : ""
                    }`}
                  >
                    <span className="input-group-text">
                      <i
                        style={{
                          display:
                            loading && searchValue && searchValue.length > 0
                              ? "none"
                              : "flex",
                        }}
                        onClick={clear}
                        className="quick-search-close ki ki-close icon-sm text-muted"
                      />
                    </span>
                  </div>
                </div>
              </form>
              <SearchDropdownResult data={data} />
            </div>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}
