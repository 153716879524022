import { runApiQuery } from "app/utils/APIFunctions";
import { ToolType } from "app/utils/Constants";
import { NodeTypeEnum } from "app/utils/LibConfig";
import { useMetaTags } from "app/utils/SeoFunction";
import { gql } from "graphql-request";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { SearchResult } from "./SearchResult";
const SEARCH_NODES = gql`
  query($kw: String!) {
    nodeQuery(keywords: $kw) {
      __typename
      guid
      title
      tags
      descr
      node_type_id
      seo_guid
    }
  }
`;
/**
 * Display a search bar (text box)
 */
export function SearchHome() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  useMetaTags("Search GardenPal", "Learn to grow plants and vegetables");

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    console.log(
      "handleSearchChange ",
      event.target.value.length,
      event.target.value
    );
    if (searchValue.length === 0) {
      setData(null);
    }
  };

  // load the options 0- make the api calls
  const loadResults = async (searchText) => {
    console.log("in : loadResults");
    const variables = {
      kw: searchText,
    };
    const data = await runApiQuery(SEARCH_NODES, variables);
    // map it into format as requried by the control
    console.log(data);

    // transform and set the data
    const searchData = data.nodeQuery?.map((node) => {
      let url;
      let nodeType = parseInt(node.node_type_id);
      if (
        nodeType === ToolType.Calculator ||
        nodeType === ToolType.Tool ||
        nodeType === ToolType.Tracker
      ) {
        url = `/${NodeTypeEnum[node.node_type_id].guid}/${
          node.seo_guid
        }?guid_type=node`;
      } else {
        url = `/${NodeTypeEnum[node.node_type_id].guid}/${node.guid}/${
          node.seo_guid
        }?guid_type=node`;
      }
      return {
        type: NodeTypeEnum[node.node_type_id].name,
        tags: node.tags,
        text: node.title,
        description: node.descr,
        iconClassName: "flaticon-psd font-brand",
        iconImage: NodeTypeEnum[node.node_type_id].iconImage,
        url: url,
      };
    });

    if (searchText.length > 4) {
      ReactGA.event({
        category: "Global Search",
        action: "Searched Keywords",
        label: searchText,
      });
    }

    console.log("search data", searchData);
    setData(searchData);
  };

  const clear = () => {
    console.log("In clear");
    setData(null);
    setSearchValue("");
  };

  useEffect(() => {
    const loaderFunc = async () => {
      console.log("In useEffect");
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (searchValue && searchValue.length > 2) {
        setLoading(true);

        await loadResults(searchValue);
        setLoading(false);
      }
    };
    loaderFunc();
  }, [searchValue]);

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <h1>Search GardenPal</h1>
          <div className="input-group" style={{ marginTop: "10px" }}>
            <input
              type="text"
              autoFocus={true}
              placeholder="Keywords..."
              value={searchValue}
              onChange={handleSearchChange}
              className="form-control"
            />

            <div
              className={`input-group-append ${
                loading ? "spinner spinner-sm spinner-primary" : ""
              }`}
            >
              <span className="input-group-text">
                <i
                  style={{
                    display:
                      loading && searchValue && searchValue.length > 0
                        ? "none"
                        : "flex",
                  }}
                  onClick={clear}
                  className="quick-search-close ki ki-close icon-sm text-muted"
                />
              </span>
            </div>
          </div>
          <SearchResult data={data} />
        </div>
      </div>
    </>
  );
}
