import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ModalProgressBar } from "../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import * as auth from "../Auth";
import { gql } from "graphql-request";
import { runApiQuery } from "app/utils/APIFunctions";
import { Badge } from "react-bootstrap";
import { Notice } from "_metronic/_partials/controls/Notice";

const UPDATE_PROFILE = gql`
  mutation($name: String!, $headline: String, $website: String) {
    updateUserProfile(name: $name, headline: $headline, website: $website) {
      __typename
      guid
    }
  }
`;

function PersonaInformation({ profile }) {
  // Fields
  const [executeUpdateMutation, setExecuteUpdateMutation] = useState(false);
  const [actionMessage, setActionMessage] = useState(null);
  // const [name, setName] = useState(null);
  // const [website, setWebsite] = useState(null);
  // const [headline, setHeadline] = useState(null);
  console.log("Profile", profile);
  const [userProfile, setUserProfile] = useState(profile ?? {});

  useEffect(() => {
    // load the options 0- make the api calls
    const performUpdateMutation = async () => {
      console.log("in : performUpdateMutation");
      const variables = {
        name: userProfile.name,
        website: userProfile.website,
        headline: userProfile.headline,
      };
      const data = await runApiQuery(UPDATE_PROFILE, variables);
      // map it into format as requried by the control
      console.log(data);
      return data;
    };

    if (executeUpdateMutation) {
      performUpdateMutation()
        .then((result) => {
          console.log(result);
          if (result["updateUserProfile"]["guid"] == null) {
            setActionMessage("Failed to save");
          } else {
            setActionMessage("Saved");
          }
          setExecuteUpdateMutation(false);
        })
        .catch((error) => {
          setActionMessage("Failed to save");
          setExecuteUpdateMutation(false);
        });
    }
  }, [executeUpdateMutation]);

  // UI Helpers
  const initialValues = {
    name: userProfile.name,
    headline: userProfile.headline,
    website: userProfile.website,
  };
  const Schema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    website: Yup.string().nullable(),
    headline: Yup.string().nullable(),
  });
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      console.log("Values", values);
      // set profile
      setUserProfile(values);
      setExecuteUpdateMutation(true);
      setSubmitting(false);
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    },
  });

  if (Object.keys(userProfile).length == 0) {
    return (
      <>
        <Notice svg="/media/svg/icons/Tools/Compass.svg">
          <p>Something went wrong! please login to continue.</p>
        </Notice>
      </>
    );
  }

  return (
    <form
      className="card card-custom card-stretch"
      onSubmit={formik.handleSubmit}
    >
      {executeUpdateMutation && <ModalProgressBar />}

      {/* begin::Header */}
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">
            Personal Information{" "}
            {actionMessage && <Badge variant="light">{actionMessage}</Badge>}
          </h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">
            Update your personal information
          </span>
        </div>
        <div className="card-toolbar">
          <button
            type="submit"
            className="btn btn-success mr-2"
            disabled={
              formik.isSubmitting || (formik.touched && !formik.isValid)
            }
          >
            Save
            {formik.isSubmitting}
          </button>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Form */}
      <div className="form">
        {/* begin::Body */}
        <div className="card-body">
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Name</label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text"
                placeholder="Name"
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "name"
                )}`}
                name="name"
                {...formik.getFieldProps("name")}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="invalid-feedback">{formik.errors.name}</div>
              ) : null}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Headline</label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text"
                placeholder="Headline"
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "headline"
                )}`}
                name="headline"
                {...formik.getFieldProps("headline")}
              />
              {formik.touched.headline && formik.errors.headline ? (
                <div className="invalid-feedback">{formik.errors.headline}</div>
              ) : null}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Website</label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text"
                placeholder="Website"
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "website"
                )}`}
                name="website"
                {...formik.getFieldProps("website")}
              />
              {formik.touched.website && formik.errors.website ? (
                <div className="invalid-feedback">{formik.errors.website}</div>
              ) : null}
            </div>
          </div>

          <div className="form-group row d-none">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Time Zone
            </label>
            <div className="col-lg-9 col-xl-6">
              <select
                className="form-control form-control-lg form-control-solid"
                name="timeZone"
                {...formik.getFieldProps("timeZone")}
              >
                <option value="International Date Line West">
                  (GMT-11:00) International Date Line West
                </option>
                <option value="Midway Island">(GMT-11:00) Midway Island</option>

                <option value="Central America">
                  (GMT-06:00) Central America
                </option>
                <option value="Central Time (US &amp; Canada)">
                  (GMT-05:00) Central Time (US &amp; Canada)
                </option>
                <option value="Eastern Time (US &amp; Canada)">
                  (GMT-04:00) Eastern Time (US &amp; Canada)
                </option>
                <option value="Indiana (East)">
                  (GMT-04:00) Indiana (East)
                </option>

                <option value="Georgetown">(GMT-04:00) Georgetown</option>

                <option value="Buenos Aires">(GMT-03:00) Buenos Aires</option>
                <option value="Newfoundland">(GMT-02:30) Newfoundland</option>
                <option value="Mid-Atlantic">(GMT-02:00) Mid-Atlantic</option>
                <option value="Cape Verde Is.">
                  (GMT-01:00) Cape Verde Is.
                </option>
                <option value="UTC">(GMT) UTC</option>
                <option value="Dublin">(GMT+01:00) Dublin</option>
                <option value="Edinburgh">(GMT+01:00) Edinburgh</option>
                <option value="London">(GMT+01:00) London</option>
                <option value="Prague">(GMT+02:00) Prague</option>
                <option value="Helsinki">(GMT+03:00) Helsinki</option>
                <option value="Abu Dhabi">(GMT+04:00) Abu Dhabi</option>
                <option value="Tehran">(GMT+04:30) Tehran</option>
                <option value="Islamabad">(GMT+05:00) Islamabad</option>
                <option value="Karachi">(GMT+05:00) Karachi</option>
                <option value="Tashkent">(GMT+05:00) Tashkent</option>
                <option value="Kathmandu">(GMT+05:45) Kathmandu</option>
                <option value="Astana">(GMT+06:00) Astana</option>
              </select>
            </div>
          </div>
        </div>
        {/* end::Body */}
      </div>
      {/* end::Form */}
    </form>
  );
}

export default PersonaInformation;
