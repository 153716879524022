import Post from "app/components/Post";
import { ToolToolbar } from "app/components/ToolToolbar";
import ErrorsPage from "app/modules/ErrorsExamples/ErrorsPage";
import AcidRefluxTool from "app/tools/health/AcidRefluxTool";
import FodmapTool from "app/tools/health/FodmapTool";
import WeightGainTool from "app/tools/health/WeightGainTool";
import { ToolType } from "app/utils/Constants";
import { recordPageView, runApiQuery } from "app/utils/APIFunctions";
import { gql } from "graphql-request";
import { default as React, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { Route, Switch } from "react-router-dom";
import { useSubheader } from "_metronic/layout/index";
import { LayoutIdToLayoutTypeMap, LayoutEnum } from "app/utils/LibConfig";
import { WeightGainToolMode } from "app/tools/health/WeightGainTool";
import BMICalculatorTool from "app/tools/health/BMICalculatorTool";
import BMRCalculatorTool from "app/tools/health/BMRCalculatorTool";
import HealthyWeightCalculatorTool from "app/tools/health/HealthyWeightCalculatorTool";
import { withRouter } from "react-router-dom";
/**
 * Represents the Tool template
 * @returns
 */

function ToolTemplate(props) {
  const toolURL = props.location.pathname;
  const [tool: Tool, setTool] = useState(null);
  const suhbeader = useSubheader();

  const GET_TOOL_QUERY = gql`
    query($url: String!) {
      toolQuery(url: $url) {
        __typename
        guid
        title
        descr
        contents
        tags
        url
        headline
        node_type_id
        layout_id
      }
    }
  `;

  useEffect(() => {
    const loadTool = async () => {
      if (tool == null) {
        const variables = {
          url: toolURL,
        };
        const data = await runApiQuery(GET_TOOL_QUERY, variables);
        //    console.log(data);
        if (data.toolQuery && data.toolQuery[0]) {
          setTool(data.toolQuery[0]);
        }
      }
    };
    loadTool();
    if (tool && suhbeader.title !== tool.title) {
      suhbeader.setTitle(tool.title);

      let breadcrumbs;

      if (tool.node_type_id === ToolType.Tool) {
        breadcrumbs = [
          {
            title: "Tools",
            pathname: "/tool/home",
          },
        ];
      } else if (tool.node_type_id === ToolType.Calculator) {
        breadcrumbs = [
          {
            title: "Calculators",
            pathname: "/calculator/home",
          },
        ];
      } else if (tool.node_type_id === ToolType.Tracker) {
        breadcrumbs = [
          {
            title: "Trackers",
            pathname: "/tracker/home",
          },
        ];
      }
      suhbeader.setBreadcrumbs(breadcrumbs);
    }
    recordPageView(toolURL);
  }, [toolURL, tool, suhbeader, GET_TOOL_QUERY, props]);

  const toolbar = (
    <div className="card-toolbar">
      <div className="example-tools">{<ToolToolbar />}</div>
    </div>
  );

  // if (isLoading) return "Loading...";
  // if (error) return <pre>{error.message}</pre>;

  return (
    <>
      {tool &&
        LayoutIdToLayoutTypeMap[tool.layout_id] ===
          LayoutEnum.RightSideBar7x && (
          <LayoutRightSidebar tool={tool} toolbar={toolbar} />
        )}
      {tool &&
        LayoutIdToLayoutTypeMap[tool.layout_id] === LayoutEnum.NoSideBar && (
          <LayoutNoSidebar tool={tool} toolbar={toolbar} />
        )}
    </>
  );
}

// template with 5 left and 7 right
// Small : 2 cols
// Medium : 3 cols
// Medium : 4 cols
// Large : 7 cols
const LayoutRightSidebar = ({ tool, toolbar }) => {
  return (
    <>
      <div className="row">
        <div className="col-md-5">
          <Switch>
            <Route
              exact
              path="/tool/fodmap"
              render={(props) => <FodmapTool tool={tool} toolbar={toolbar} />}
            />
            <Route
              exact
              path="/tool/acid-reflux"
              render={(props) => (
                <AcidRefluxTool tool={tool} toolbar={toolbar} />
              )}
            />

            <Route
              exact
              path="/tool/healthy-weight-calculator"
              render={(props) => (
                <HealthyWeightCalculatorTool tool={tool} toolbar={toolbar} />
              )}
            />
            <Route
              exact
              path="/tool/bmi-calculator"
              render={(props) => (
                <BMICalculatorTool tool={tool} toolbar={toolbar} />
              )}
            />
            <Route
              exact
              path="/tool/bmr-calculator"
              render={(props) => (
                <BMRCalculatorTool tool={tool} toolbar={toolbar} />
              )}
            />
            <Route path="*" component={ErrorsPage} />
          </Switch>
        </div>
        <div className="col-md-7">
          <Post contents={tool.contents} title={tool.headline} />
        </div>
      </div>
    </>
  );
};

// template with left only
const LayoutNoSidebar = ({ tool, toolbar }) => {
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <Switch>
            <Route
              exact
              path="/tool/weight-gain-calculator"
              render={(props) => (
                <WeightGainTool
                  tool={tool}
                  toolbar={toolbar}
                  mode={WeightGainToolMode.GAIN}
                />
              )}
            />
            <Route
              exact
              path="/tool/weight-loss-calculator"
              render={(props) => (
                <WeightGainTool
                  tool={tool}
                  toolbar={toolbar}
                  mode={WeightGainToolMode.LOSS}
                />
              )}
            />
            <Route path="*" component={ErrorsPage} />
          </Switch>
        </div>
        <br></br>
        <div className="row">
          <div className="col-md-12">
            <Post contents={tool.contents} title={tool.headline} />
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(ToolTemplate);
