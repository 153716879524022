// @flow

export type Tool = {
  guid: number,
  title: string,
  descr: string,
  contents: string,
  tags: string,
  url: string,
  node_type_id: number,
  post_title: string,
};

export const ToolType = {
  Tool: 8,
  Calculator: 9,
  Tracker: 10,
};

export type Product = {
  guid: number,
  title: string,
  descr: string,
  contents: string,
  tags: string,
};

export type Item = {
  guid: number,
  title: string,
  descr: string,
  contents: string,
  tags: string,
  type: number,
  class: number,
  products?: Array<Product>,
};

export type RemedyStep = {
  guid: number,
  title: string,
  descr: string,
  contents: string,
};

export type Remedy = {
  index: number,
  guid: number,
  title: string,
  descr: string,
  contents: string,
  tags: string,
  remedies: Array<RemedyStep>,
  items?: Array<Item>,
};

export type Problem = {
  guid: number,
  title: string,
  descr: string,
  contents: string,
  tags: string,
  seo_guid: string,
  remedies: Array<Remedy>,
};
