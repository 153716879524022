// @flow
import "../../_metronic/_assets/sass/pages/error/error-3.scss";
import { Notice } from "_metronic/_partials/controls/Notice";
import { useSubheader } from "_metronic/layout/index";

import React from "react";
import { useEffect } from "react";
import ReactGA from "react-ga";
import { useMetaTags } from "app/utils/SeoFunction";
import ProblemGrid from "../components/ProblemGrid";
import { recordPageView } from "app/utils/APIFunctions";

export const RemedyHome = (): React$MixedElement => {
  const suhbeader = useSubheader();
  useMetaTags(
    "Gardening Remedies",
    "Get relief from common gardening problems with time tested solutions that simply work and maximise your output."
  );

  useEffect(() => {
    recordPageView("/remedy/home");
    suhbeader.setTitle("Remedies");
  }, [suhbeader]);

  return (
    <>
      <Notice svg="/media/svg/icons/Tools/Compass.svg">
        <h1>Remedies</h1>
        Get relief from common gardening problems with time tested solutions
        that simply work and maximise your output.
      </Notice>
      <ProblemGrid></ProblemGrid>
    </>
  );
};
